"use client";
import styles from "./EventImage.module.css";
import Image from "next/image";
import useImageLoading from "@hooks/useImageLoading";
import EventImageSkeleton from "@components/Skeletons/EventImageSkeleton/EventImageSkeleton";

const EventImage = ({ imageUrl, name }) => {
  const isImageLoaded = useImageLoading(imageUrl);
  if (!isImageLoaded) return <EventImageSkeleton />;

  return (
    <div className={styles.img_wrapper}>
      <Image
        className={styles.normal_img}
        src={imageUrl}
        alt={name}
        width={506}
        height={900}
        priority={true}
        unoptimized={true}
      />
    </div>
  );
};

export default EventImage;
