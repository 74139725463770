import React from "react";
import styles from "./Footer.module.css";
import Image from "next/image";
import { FaLinkedinIn, FaInstagram, FaFacebookF } from "react-icons/fa";
const Footer = ({ isDetails }) => {
  return (
    <footer
      className={`${styles.footer} ${
        isDetails ?? false ? `${styles.translate}` : ""
      }`}
    >
      <div className={styles.wrap}>
        <Image
          src="/assets/images/logo.png"
          alt="tick'it"
          width={150}
          height={150}
          className={styles.tickit}
        />
        <div className={styles.col}>
          <h1>The Tick'it</h1>
          <h1>mobile app is</h1>
          <h1 className={styles.h1_3}>now available</h1>
        </div>
        <div className={styles.col}>
          <h3>Quick links</h3>
          <a
            href={`mailto:${"support@tickit.co"}?subject=SendMail&body=Description`}
          >
            Support
          </a>
          <a href=
"https://firebasestorage.googleapis.com/v0/b/ticket-development-6f3af.appspot.com/o/Terms%20%26%20Policies%2FNew%20Privacy%20Policy%20-%20Tick'it.pdf?alt=media&token=a727d1dc-511c-4cd4-8300-7483a3ddbcbe"          >
            Privacy Policy
          </a>
          <a href="https://firebasestorage.googleapis.com/v0/b/ticket-development-6f3af.appspot.com/o/Terms%20%26%20Policies%2FNew%20Tick'it%20Terms%20and%20Condition%20Updated.pdf?alt=media&token=b1cd7046-8adb-4da3-9b1a-309cdfe040cc"          >
            Terms and Conditions
          </a>
        </div>
        <div className={styles.col}>
          <h3>For iOS</h3>
          <a
            href="https://app.adjust.com/1atilkb8?campaign=Website"
            className={styles.btn}
          >
            <img src="/assets/images/apple.png" alt="apple" />
          </a>
          <h3>For Android</h3>
          <a
            href="https://app.adjust.com/1atilkb8?campaign=Website"
            className={styles.btn}
          >
            <img src="/assets/images/google.png" alt="google" />
          </a>
        </div>
      </div>
      <div className={styles.row}>
        <span>Copyright © 2023 TICK'IT&nbsp;SAL. All rights reserved.</span>
        <div>
          <a
            href="https://www.linkedin.com/company/tick-it-app/mycompany/"
            className={styles.links}
          >
            {" "}
            <FaLinkedinIn className={styles.logo} />{" "}
          </a>
          <a
            href="https://www.facebook.com/TickIt-Support-115111674523738/"
            className={styles.links}
          >
            {" "}
            <FaFacebookF className={styles.logo} />
          </a>
          <a href="https://www.instagram.com/tick1t/" className={styles.links}>
            {" "}
            <FaInstagram className={styles.logo} />{" "}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
