"use client";
import Link from "next/link";
import Image from "next/image";
import styles from "./EventCard.module.css";
import useFormatDates from "@hooks/useFormatDates";
import { EventCategories } from "@components/Event";
import { useState } from "react";

const EventCard = ({ event, myEvent = false, eventId, hide }) => {
  const [eventStartDate, startRange, endRange] = useFormatDates(
    event.startDate,
    event.endDate,
    event.timezoneMap
  );
  const musicTypes = event.musicTypes;
  const [isLoading, setIsLoading] = useState(false);
  const [clickedLoading, setClickedLoading] = useState(false);
  const handleImageLoad = () => {
    setIsLoading(false);
  };
  // console.log(event);
  return (
    <>
      {!isLoading ? (
        <div
          className={`${styles.eventCard} ${
            clickedLoading ? styles.cursor : ""
          }`}
        >
          <Link
            href={`/${myEvent ? "my-events" : "events"}/${
              myEvent ? eventId : event.eventID
            }`}
            className={`${clickedLoading ? styles.cursor : ""}`}
            prefetch={false}
            onClick={() => {
              setClickedLoading(true);
            }}
          >
            <div className={styles.imageContainer}>
              <Image
                src={event.imageUrl}
                alt={event.name}
                className={styles.eventImage}
                width={400}
                height={200}
                // priority={true}
                // onLoad={handleImageLoad}
                unoptimized={true}
              />
            </div>
          </Link>
          <h3 className={styles.name}>{event.name}</h3>
          <div className={styles.event_info}>
            <span>{event?.orgSnippet?.orgName || ""} </span>
            <span>|</span>
            <span>{eventStartDate}</span>
          </div>
          {hide ? <div></div> : <EventCategories musicTypes={musicTypes} />}
        </div>
      ) : (
        <div className={styles.loading}>Loading...</div>
      )}
    </>
  );
};

export default EventCard;
